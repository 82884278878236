<template>
  <div>
    <a-card :title="$t('上架')">
      <a-button slot="extra" type="primary" ghost @click="goBack"><a-icon type="left" />{{ $t("返回") }}</a-button>

      <div style="margin-top: 16px">
        <a-table
          rowKey="id"
          size="small"
          :loading="tableLoading"
          :columns="curColumns"
          :data-source="shelveMaterialItems"
          :pagination="false"
          :scroll="{ x: 1400 }"
        >
          <template slot="shelve_quantity" slot-scope="value, item">
            <a-input-number v-model="item.shelve_quantity" :min="0" size="small" />
          </template>
          <template slot="location" slot-scope="value, item">
            <LocationSearchSelect
              v-model="item.location"
              :placeholder="$t('请输入或选择库位')"
              :warehouse="$route.query.warehouse"
              :area="$route.query.area"
              size="small"
            />
          </template>
          <template slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button type="danger" @click="removeMaterial(item, index)">{{ $t("删除") }}</a-button>
              <a-button type="primary" @click="copyMaterial(item, index)">{{ $t("复制") }}</a-button>
            </a-button-group>
          </template>
        </a-table>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="createLoading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { shelveOrderMaterials, shelveRecordCreate } from "@/api/stockIn";

export default {
  components: {
    LocationSearchSelect: () => import("@/components/LocationSearchSelect"),
  },
  data() {
    return {
      tableLoading: false,
      createLoading: false,
      shelveOrderId: null,
      shelveMaterialItems: [],
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          fixed: "left",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: this.$t("货品名称"),
          dataIndex: "material_name",
          width: 150,
          fixed: "left",
          ellipsis: true,
          customRender: (_value, item) => item.material_item.name,
        },
        {
          title: this.$t("库位"),
          dataIndex: "location",
          width: 180,
          scopedSlots: { customRender: "location" },
        },
        {
          title: this.$t("上架数量"),
          dataIndex: "shelve_quantity",
          scopedSlots: { customRender: "shelve_quantity" },
          width: 120,
        },
        {
          title: this.$t("货品编号"),
          dataIndex: "material_number",
          width: 180,
          ellipsis: true,
          customRender: (_value, item) => item.material_item.number,
        },
        {
          title: this.$t("批次编号"),
          dataIndex: "material_name",
          width: 180,
          ellipsis: true,
          customRender: (_value, item) => item.batch_item.number,
        },
        {
          title: this.$t("质检状态"),
          dataIndex: "status_display",
          key: "quality_inspection_status",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("待上架数量"),
          width: 120,
          ellipsis: true,
          dataIndex: "remain_quantity",
        },
        {
          title: this.$t("推荐库区"),
          dataIndex: "recommend_areas",
          customRender: (_value, item) => item.recommend_areas.join(", "),
          width: 200,
          ellipsis: true,
        },
        {
          title: this.$t("操作"),
          dataIndex: "action",
          fixed: "right",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  computed: {
    enableQualityInspection() {
      return this.$store.state.user.enableQualityInspection;
    },
    curColumns() {
      const items = [];
      for (const item of this.columns) {
        if (!this.enableQualityInspection && item.key === "quality_inspection_status") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
  },
  methods: {
    initData() {
      this.shelveOrderId = this.$route.query.id;
      this.getMaterials();
    },
    goBack() {
      this.$router.go(-1);
    },
    getMaterials() {
      this.tableLoading = true;
      shelveOrderMaterials({ id: this.$route.query.id })
        .then((data) => {
          const shelveMaterialItems = [];
          for (const item of data) {
            if (item.remain_quantity > 0 && !item.pallet_number) {
              shelveMaterialItems.push({ ...item, shelve_quantity: item.remain_quantity });
            }
          }
          this.shelveMaterialItems = shelveMaterialItems;

          if (this.shelveMaterialItems.length === 0) {
            this.$message.warn(this.$t("请先收货!"));
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    create() {
      if (this.shelveMaterialItems.length == 0) {
        this.$message.warn(this.$t("没有上架货品"));
        return;
      }

      for (let item of this.shelveMaterialItems) {
        if (!item.location) {
          this.$message.warn(this.$t("未选择库位"));
          return;
        }
      }

      const shelveRecordMaterialItems = [];
      for (const item of this.shelveMaterialItems) {
        shelveRecordMaterialItems.push({
          shelve_material: item.id,
          location: item.location,
          shelve_quantity: item.shelve_quantity,
        });
      }

      this.createLoading = true;
      shelveRecordCreate({
        shelve_order: this.shelveOrderId,
        shelve_record_material_items: shelveRecordMaterialItems,
      })
        .then((_data) => {
          this.$message.success(this.$t("创建成功"));
          this.$router.go(-1);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    removeMaterial(_item, index) {
      this.shelveMaterialItems.splice(index, 1);
    },
    copyMaterial(item, index) {
      this.shelveMaterialItems.splice(index, 0, { ...item });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped></style>
